

.main {
    background-color: #ffffff;
}
.main header {
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.main header h3 {
    text-align: center;
    font-family: hm;
    font-size: 38px;
    line-height:.6px;
    margin: 20px 0;
    color: #ffffff;
}
.main header p {
    text-align: center;
    width: 50%;
    margin:20px;
    letter-spacing: .6px;
    line-height: 30px;
    color: #ffffff;
    font-size: 17px;
    font-family: hr;
}

.container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    margin-top: 100px;
}
.container h3 {
    font-family: hr;
    font-size: 30px;
    letter-spacing: .8px;
    color: #026095;
    margin-bottom:20px ;
}
.question {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    transition: all 400ms;
}

.question p {
    color: #026095;
    font-size: 19px;
    font-family: hm;
    letter-spacing: .6px;
    margin-top:5px ;
}
.question h4 {
    font-size: 20px;
    color: #026095;
}
.one , .two{
    background-color: #ffffff;
    border:1px solid #026095;
    margin: 15px 0;
    
    cursor: pointer;
}

.answer p {
    font-family: hr;
    font-size: 15px;
    letter-spacing: .6px;
    line-height: 28px;
}
.two .answer ul li{
    list-style: none;
    line-height: 28px;
    letter-spacing: .6px;
    font-family: hr;
    font-size: 15px;
    margin:10px 0 ;
 
}
.two .answer ul li i{
    color: #026095;
    margin-right:20px ;
}
@keyframes show_answers{
    from {
       
        opacity: .8;
        transform: scale(.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}


.answer {
    background-color: #ebf8ff;
    padding: 15px;
    display: none;
}
.show_answer_one,.show_answer_two,.show_answer_three,.show_answer_four,.show_answer_five,.show_answer_six,.show_answer_seven,.show_answer_eight {
    display: block;
    animation:show_answers 400ms;
}

.answer p {
    margin-top: 10px;
}

.change_b_one, .change_b_two, .change_b_three,.change_b_four, .change_b_five,.change_b_six,.change_b_seven,.change_b_eight {
    background-color: #026095;
}
.change_b_one h4, .change_b_two h4, .change_b_three h4,.change_b_four h4, .change_b_five h4,.change_b_six h4,.change_b_seven h4,.change_b_eight h4,.change_b_one p, .change_b_two p, .change_b_three p,.change_b_four p, .change_b_five p,.change_b_six p,.change_b_seven p,.change_b_eight p {
        color: #ffffff;
}

@media only screen and (max-width: 1000px) {
    
.main {
    background-color: #ffffff;
}
.main header {
    width: 100%;
    height: 40vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
}
.main header h3 {
    position: relative;
    top:8%;
    text-align: center;
    font-family: hm;
    font-size: 22px;
    line-height:.6px;
    margin: 20px 0;
    color: #ffffff;
}
.main header p {
    text-align: center;
    width: 100%;
    margin:20px;
    letter-spacing: .6px;
    line-height: 30px;
    color: #ffffff;
    font-size: 14px;
    font-family: hr;
}

.container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
}
.container h3 {
    font-family: hr;
    font-size: 20px;
    letter-spacing: .8px;
    color: #026095;
    margin-bottom:10px ;
}
.question {
    padding: 6px;
    display: flex;
    justify-content: space-between;
    transition: all 400ms;
}

.question p {
    color: #026095;
    font-size: 14px;
    font-family: hm;
    letter-spacing: .6px;
    
    width: 90%;
    align-self: center;
    position: relative;
    top: 8px;
}
.question h4 {
    padding: 0 5px;
    font-size: 15px;
    color: #026095;
    align-self: center;
    margin: 0 10px;
}
.one , .two{
    background-color: #ffffff;
    border:1px solid #026095;
    margin: 10px 0;
    
    cursor: pointer;
}

.answer p {
    font-family: hr;
    font-size: 15px;
    letter-spacing: .6px;
    line-height: 28px;
}
.two .answer ul li{
    list-style: none;
    line-height: 28px;
    letter-spacing: .6px;
    font-family: hr;
    font-size: 15px;
    margin:10px 0 ;
 
}
.two .answer ul li i{
    color: #026095;
    margin-right:20px ;
}



.answer {
    background-color: #ebf8ff;
    padding: 5px;
    display: none;
}
.show_answer_one,.show_answer_two,.show_answer_three,.show_answer_four,.show_answer_five,.show_answer_six,.show_answer_seven,.show_answer_eight {
    display: block;
    animation:show_answers 400ms;
}

.answer p {
    margin-top: 10px;
}

.change_b_one, .change_b_two, .change_b_three,.change_b_four, .change_b_five,.change_b_six,.change_b_seven,.change_b_eight {
    background-color: #026095;
}
.change_b_one h4, .change_b_two h4, .change_b_three h4,.change_b_four h4, .change_b_five h4,.change_b_six h4,.change_b_seven h4,.change_b_eight h4,.change_b_one p, .change_b_two p, .change_b_three p,.change_b_four p, .change_b_five p,.change_b_six p,.change_b_seven p,.change_b_eight p {
        color: #ffffff;
}
}