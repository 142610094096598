
.main{
    background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);  

}
.main header{
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
}
.main header h3 {
    text-transform: uppercase;
    letter-spacing: .6px;
        padding: 10px;
        font-family: hm;
        font-size: 28px;
        width: 40%;
        margin:  0 auto;
        color: #ffffff;
        font-family: hl;
        font-size: 32px;
        padding: 20px;
        text-align:center;   
        z-index: -10;
        margin-top: 70px;
    
}
.container{
    width: 99%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.one_container img{
    width: 90%;
    height: 350px;
    align-self: center;
    box-shadow: 0 8px 8px #b0adad, 0 8px 8px #b0adad;
    border-radius: 25px;
}
.one_main_container, .two_main_container,.three_main_container{
    display: flex;
    justify-content: space-between;
  
}
.one_container{
    box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    transform: scale(.85);
    transition: all 600ms;
    
}
.one_container:hover {
    transform: scale(.9);
    transition: all 600ms;
}
.one_container h3 {
    align-self: center;
    font-family: hm;
    font-size: 28px;
    color: #0079bf;
    margin-top: 20px;
}
.one_container p {
    font-family: hr;
    font-size: 15px;
    letter-spacing: .6px;
    line-height: 28px;
    margin-top:20px ;
}


@media only screen and (max-width: 1000px) {

    .main{
        background-color: #f1f1f3;
        
    }
    .main header{
        width: 100%;
        height: 25vh;
        background: linear-gradient(135deg,#0079bf, #5067c5);
        border-top: 1px solid #ffffff;
    }
    .main header h3 {
        text-transform: uppercase;
        letter-spacing: .6px;
            padding: 10px;
            font-family: hm;
            font-size: 26px;
            width: 90%;
            margin:  0 auto;
            color: #ffffff;
            font-family: hl;
            font-size: 26px;
            padding: 10px;
            text-align:center;   
            z-index: -10;
            margin-top: 50px;
        
    }
    .container{
        width: 99%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .one_container img{
        width: 99%;
        height: 200px;
        align-self: center;
        box-shadow: 0 8px 8px #b0adad, 0 8px 8px #b0adad;
        border-radius: 25px;
    }
    .one_main_container, .two_main_container,.three_main_container{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
    .one_container{
        box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        transform: scale(.95);
        transition: all 600ms;
    }
    .one_container:hover {
        transform: scale(.95);
        transition: all 600ms;
    }
    .one_container h3 {
        align-self: center;
        font-family: hm;
        font-size: 26px;
        color: #0079bf;
        margin-top: 20px;
    }
    .one_container p {
        font-family: hr;
        font-size: 14px;
        letter-spacing: .6px;
        line-height: 28px;
        margin-top:8px ;
        color: #026095;
    }


}