.container{
    display: none;
}
.mybutton{
    position: fixed;
    left: 96%;
    top:92%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #858585;
    color: #ffffff;
    padding: 6px;
    z-index: 833333;
    border-radius: 55px;
    cursor: pointer;
    height: 50px;
    width: 50px;
}
.mybutton i {
    font-size: 24px;
    align-self: center;
}
.mybutton p{
    align-self: center;
    font-family: hr;
    font-size: 13px!important;
}

@media only screen and (max-width: 1000px) {
    .mybutton{
        position: fixed;
        left: 88%;
        top:85%;
        display: flex;
        flex-direction: column;
        background-color: #858585;
        color: #ffffff;
        padding: 10px;
        z-index: 833333333;
        border-radius: 55px;
        cursor: pointer;
        height: 40px;
        width: 40px;
        transition: all 300ms;
    }
    .mybutton i {
        font-size: 24px;
        margin-top:-3px ;
    }
    .mybutton p{
        display: none;
        align-self: center;
        font-family: hr;
        font-size: 14px!important;
    }
    
}