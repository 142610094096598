@keyframes show_navanim{
    from{
        opacity: 0.5;
    }
    to{
        opacity: 1;
    }
}
.show_navbar{
    display: block;   
    animation:  show_navanim 700ms;
}

.rotate_caret {
    transform: rotate(180deg);
}

.show_dropdown_one {
    display: block;
}