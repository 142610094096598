
.main{
    background-color:whitesmoke;

}
.main header{
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
}
.main header h3 {
    
        width: 99%;
        margin:  0 auto;
        color: #ffffff;
        font-family: hl;
        font-size: 28px;
        padding: 15px 12px;
        text-align:center;   
        z-index: -10;
        margin-top: 70px;
        letter-spacing: .6px;
        opacity: .9;
}

.container {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.one, .two {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0 8px 8px #b0adad, 0 8px 8px #b0adad;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #D0E1D5;
    transform: scale(.95);
    transition:  all 600ms;
}
.one:hover, .two:hover {
    transform: scale(1);
    transition:  all 600ms;
}
.two{
    background-color: #c9beb3;
}


.one ul li, .two ul li {
 
    list-style: none;
    font-size: 15px;
    font-family: hr;
    letter-spacing: .6px;
    line-height: 28px;
    font-size: 15px;
  
}

.one ul li i, .two ul li i {
    display: inline;
    margin-right:8px ;
    color: #F3452C;
}
.one p , .two p {
    font-family: hr;
    letter-spacing: .6px;
    line-height: 28px;
    font-size: 15px;
    margin-top:18px ;
}

.title{
    text-align: center;
    width: 50%;
    margin: 10px auto;
}
.title h2{
    background-color: #ffffff;
    color: #F3452C;
    font-family: hl;
    letter-spacing: .6px;
    padding: 5px;
    box-shadow: 0 8px 8px #b0adad, 0 8px 8px #b0adad;
}
.one h3, .two h3 {
    color: #F3452C;
    font-size: 18px;
    font-family: hm;
    letter-spacing: .6px;
    line-height: 28px;
    font-size: 15px;
}
@media only screen and (max-width: 1000px) {
    
.main{
    background-color:whitesmoke;

}
.main header{
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
}
.main header h3 {
    
        width: 97%;
        margin:  0 auto;
        color: #ffffff;
        font-family: hl;
        font-size: 23px;
        padding: 15px 12px;
        text-align:center;   
        z-index: -10;
        margin-top: 30px;
        letter-spacing: .5px;
        line-height: 39px;
        opacity: .9;
}

.container {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.one, .two {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0 8px 8px #b0adad, 0 8px 8px #b0adad;
    margin: 0px 0;
    padding: 10px 5px;
    background-color: #D0E1D5;
    transform: scale(.9);
    transition:  all 600ms;
}
.one:hover, .two:hover {
    transform: scale(.95);
    transition:  all 600ms;
}
.two{
 
    background-color: #c9beb3;
}
.one ul , .two ul {
    margin-left:10px ;
}
.one ul li, .two ul li {
    list-style: none;
    font-size: 15px;
    font-family: hr;
    letter-spacing: .6px;
    line-height: 28px;
    font-size: 15px;
    text-align: justify;
}
.one ul li i, .two ul li i {
    margin-right:8px ;
    color: #F3452C;
}
.one p , .two p {
    font-family: hr;
    letter-spacing: .6px;
    line-height: 28px;
    font-size: 15px;
    margin: 12px 0;
}

.title{
    text-align: center;
    width: 80%;
    margin: 0 auto;
    vertical-align: middle;
}
.title h2{
    background-color: #ffffff;
    color: #F3452C;
    font-family: hl;
    letter-spacing: .6px;
    padding: 5px;
    margin:8px 0 ;
    box-shadow: 0 8px 8px #b0adad, 0 8px 8px #b0adad;
}
.one h3, .two h3 {
    color: #F3452C;
    font-size: 18px;
    font-family: hm;
    letter-spacing: .6px;
    line-height: 28px;
    font-size: 15px;
}
}
