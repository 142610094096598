
/*footer_container*/

.footer_container {
    background:#131313;
    padding: 10px 0px;

}

.footer{
    display: flex;
    width: 100%;

    justify-content: space-evenly;
    height: 30vh;
    padding: 20px 0;
    padding-bottom: 30px!important;
    border-bottom: .5px solid whitesmoke;
}

.footer_title_div {
    display: flex;
    height: 100%;
    width: 30%;
}
.footer_title {
    
    font-weight: bolder;
    align-self: center;
    color: #c6c6c8 ;
    font-size: 24px;
    font-family: hm;
    position: relative;
    top:-15%;
    margin-left: 10px;
    letter-spacing: .6px;
}
.footer_title_div img{
    width: 100px;
    height: 100px;
}


.footer_info_div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
    height: 100%;
    padding: 5px;
}
.footer_info_div div{
    margin-top: 20px;
}
.footer_info_div p {
    color: #c6c6c8;
    font-size: 14px;
    margin-top: 1px;
    font-family: hr;
    letter-spacing: .6px;
    line-height:25px;
}

.footer_bold {
    font-size: 16px!important;
    font-family: hm!important;
    color: #c6c6c8;
    margin: 2px 0;
    letter-spacing: .6px;
}

.newsletter {
    height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
    letter-spacing: .6px;
}
.newsletter input {
    outline: none;
    border: none;
    margin-top: 20px;
    background:#131313;
}

.newsletter_header {
    font-size: 16px!important;
   
    color: #c6c6c8;
}
.newsletter p {
    margin-top: 10px;
    font-size: 14px;
    color: #c6c6c8;
    letter-spacing: .6px;
}
.input_form {
    width: 100%;
}
.input_form input {
    color: #c6c6c8;
    font-size: 14px;
    border: 1px solid #c6c6c8;
    padding: 8px 10px;
    width: 60%;
}
.input_form button{
    outline: none;
    border: none;
    background-color: #c6c6c8;
    font-size: 14px;
    opacity: .8;
    color: #131313;;
    border: 1px solid #c6c6c8;
    padding: 8px 10px;
    margin-left: 10px;
}
.input_form input::placeholder {
    color: #c6c6c8;
}
.input_form button:hover{
    background-color: #c6c6c8;
    
    opacity: 1;
}

.input_form p {
    margin: 10px 0;
}

.copyright {
    display: flex;
    background:#131313;
    justify-content: space-evenly;
    margin-top:10px ;
    padding: 10px 0;
    font-size: 14px;
    color: #c6c6c8;
 
}
.copyright div {
    align-self: center;
}
@media only screen and (max-width: 1000px) { 

    
/*footer_container*/

.footer_container {
  
    background:#131313;
    padding: 15px 5px;
    height: 100%;
}

.footer{
    display: flex;
    width: 90%;
    margin: 0 auto;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
}

.footer_title_div {
    display: flex;
    height: 100%;
    width: 100%;
}
.footer_title {
    font-weight: bolder;
    align-self: center;
    color: #c6c6c8;
    font-size: 24px;
    font-family: hm;
    position: relative;
    top:-10%;
    margin-left: 10px;
}
.footer_title_div img{
    width: 80px;
    height: 80px;
}


.footer_info_div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 10px;
    text-align: center;
}
.footer_info_div div{
    margin-top: 20px;
}
.footer_info_div p {
    color: #c6c6c8;
    font-size: 14px;
    margin-top: 10px;
    font-family: hr;
}

.footer_bold {
    font-size: 18px!important;
    font-family: hm!important;
    color: #c6c6c8;
    margin: 10px 0;
}

.newsletter {
    height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;

}
.newsletter input {
    outline: none;
    border: none;
    margin-top: 20px;
    background:#131313;
    width: 100%;
}

.newsletter_header {
    font-size: 18px!important;
    
    color: #c6c6c8;
}
.newsletter p {
    margin-top: 10px;
    font-size: 14px;
    color: #c6c6c8;
}
.input_form {
    width: 100%;
}
.input_form input {
    color: #c6c6c8;
    font-size: 14px;
    border: 1px solid #c6c6c8;
    padding: 10px 15px;
    width: 100%;
}
.input_form button{
    outline: none;
    border: none;
    width: 50%;
    align-self: center;
    background-color: #c6c6c8;
    font-size: 14px;
    opacity: .8;
    color: #131313;;
    border: 1px solid #c6c6c8;
    padding: 8px 12px;
}
.input_form input::placeholder {
    color: #c6c6c8;
}
.input_form button:hover{
    background-color: #c6c6c8;
    
    opacity: 1;
}

.input_form p {
    margin: 10px;
}

.copyright {
    background:#131313;
    width: 100%;
    margin: 0 auto;
    border-top: .3px solid #c6c6c8;
    text-align: center;
    padding: 10px 0;
    font-size: 15px;
    color: #c6c6c8;
    flex-direction: column;
}
.copyright div {
    margin:10px 0 ;
}
.phone {
    display: flex!important;
    flex-direction: column!important;
}
.phone p {
    display: block!important;
}
}