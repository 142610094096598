@font-face {
    font-family: hr;
    src: url(./fonts/hr.otf);
}
@font-face {
    font-family: hl;
    src: url(./fonts/hl.otf);
}
@font-face {
    font-family: hm;
    src: url(./fonts/hm.otf);
}
.container{
    width: 100%;
    background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);  
   
}
.header{

    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
 

}

.title {
    
    text-transform: uppercase;
    letter-spacing: .6px;
    padding: 10px;
    font-family: hm;
    font-size: 28px;
    width: 40%;
    margin:  0 auto;
 
    color: #ffffff;
    font-family: hl;
    font-size: 32px;
    padding: 20px;
    text-align:center;   
    z-index: -10;
    margin-top: 70px;

}
.one_container {
    color: #656667;
    display: flex;
    width: 78%;
    margin: 0 auto;
    justify-content: space-between;
}
.one_two {
    background-color: #ffffff;
    margin: 20px 10px;
}
.one_two h3 {
    background-color: #00587c;
    color: #ffffff;
    padding:  10px 20px;
    text-align: center;
}
.one_two ul{
    margin: 20px 10px;
    list-style: none;
}
.one_two ul li {
    list-style: none;
    margin:10px 45px ;
}
.one_two ul li a{
    font-family: hm;
    font-size: 14px;
    color: #88aabe;
    color: #00587c;
    line-height:28px;
    text-decoration: none;
}
.one_two ul li a:hover{
        text-decoration: underline;
}
.one_two ul li a i {
    margin-right: 10px;
}
.content_one{
    align-self: center;
    width: 50%;
}
.content {
    
    padding: 25px;
    width: 100%!important;
    margin:0 auto;
    color: #656667;
}
.ul_div {
    display: flex;
    justify-content: space-between;
}
.question {
    font-family: hm;
    letter-spacing: .6px;
    font-size: 16px;
    color: #00587c;
    margin:  10 0px;
    margin-left: 25px;
}
.question_bold {
    font-family: hm;
    letter-spacing: .6px;
    font-size: 20px;
    color: #00587c;
    margin:  10px 0px;
}
.what_is_addiction p ,.dummy{
    font-family: hr;
    font-size: 15px;
    margin:  10px 0px;
    line-height: 28px;
    margin-left: 20px;
}
 .someone{
    font-size: 18px;
    margin-left:20px ;
}
.content_one ul, .two ul, .four ul, .six ul {
    margin:10px 0;
    margin-left: 50px;
    list-style: none;
}
.content_one ul li, .two ul li, .four ul li .six ul li{
    line-height: 26px;
    font-size: 14px;
    font-family: hr;
    font-weight: 20!important;
    list-style: #000055;
}
.content_one ul li::before, .four ul li::before, .two ul li::before ,.six ul li::before{
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #000055; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }

.two,.three,.four, .five, .six{
     
    padding: 25px;
    width: 80%;
    margin:0 auto;
    color: #656667;
    font-size: 14px;
    line-height: 26px;
    font-family: hr;
}
.two p {
    font-family: hr;
    font-size: 14px;
    line-height: 26px;
}
.three p {
    font-family: hr;
    font-size: 14px;
    line-height: 26px;
    margin: 8px;
    letter-spacing: .6px;
}
.marginleft{
    font-family: hr;
    font-size: 14px;
    letter-spacing: .6px;
    line-height: 26px;
    margin-left:20px ;
    margin-top:15px ;
}
.four_ul,.six_ul {
    margin: 10px;
    margin-left:50px;
}

@media only screen and (max-width: 1000px) {

    .header{
        height: 30vh!important;    
    }
    
    .title {
        
        text-transform: uppercase;
        letter-spacing: .6px;
        width: 90%;
        font-family: hm;
        font-size: 22px;
       
        margin:  0 auto;
     
        color: #ffffff;
        font-family: hl;
        font-size: 24px;
        padding: 8px;
        text-align:center;   
        z-index: -10;
        margin-top: 60px;
    
    }
    .one_container {
       
        display: flex;
        width: 90%!important;
        margin: 0 auto;
        flex-direction: column;
        justify-content: space-between;
    }
    .one_two {
        background-color: #ffffff;
        margin: 20px 5px;
        display: none;
    }
    .one_two h3 {
        background-color: #00587c;
        color: #ffffff;
        padding:  10px 10px;
    }
    .one_two ul{
        padding: 10px 20px;
        margin: 20px 10px;
        list-style: none;
    }
    
    .one_two ul li a{
        font-family: hm;
        font-size: 14px;
        color: #88aabe;
        color: #00587c;
        line-height:35px;
        text-decoration: none;
    }
    .one_two ul li a:hover{
            text-decoration: underline;
    }
    .one_two ul li a i {
        margin-right: 10px;
    }
    .content_one{
        align-self: center;
        width: 100%;
    }
    .content {
        padding: 5px;
        width: 100%!important;
        margin:0 auto;
        color: #656667;
    }
    .ul_div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
 
    .what_is_addiction p ,.dummy{
        font-family: hr;
        font-size: 15px;
        margin:  10px 0px;
        line-height: 28px;
        margin-left: 5px;
    }
     .someone{
        font-size: 15px;
        margin-left:0px ;
    }
    .content_one ul, .two ul, .four ul, .six ul {
        margin:10px 0;
        margin-left: 40px;
        list-style: none;
    }
    .content_one ul li, .two ul li, .four ul li .six ul li{
        line-height: 26px;
        font-size: 14px;
        font-family: hr;
        font-weight: 20!important;
        list-style: #000055;
    }
    .content_one ul li::before, .four ul li::before, .two ul li::before ,.six ul li::before{
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #000055; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    
    .two,.three,.four, .five, .six{
         
        padding: 5px;
        width: 100%;
        margin:0 auto;
        color: #656667;
        font-size: 14px;
        line-height: 26px;
        font-family: hr;
    }
    .two p {
        font-family: hr;
        font-size: 14px;
        line-height: 26px;
    }
    .three p {
        font-family: hr;
        font-size: 14px;
        line-height: 26px;
        margin: 0px;
        letter-spacing: .6px;
    }
    .marginleft{
        font-family: hr;
        font-size: 14px;
        letter-spacing: .6px;
        line-height: 26px;
        margin-left:0px ;
        margin-top:0px ;
    }
    .four_ul,.six_ul {
        margin: 0px;
        margin-left:0px;
    }
    .question {
        font-family: hm;
        letter-spacing: .6px;
        font-size: 14px;
        color: #00587c;
        margin:  10px 0px;
        margin-left: 10px;
    }
    .question_bold {
        font-family: hm;
        letter-spacing: .5px;
        font-size: 16px;
        color: #00587c;
        margin:  17px 0px;
        margin-left:0px!important;
    }
    p {
        line-height: 30px;
        font-size: 13px;
        margin: 20px 0;
        margin-left: 20px!important;
    }
    .four_ul {
        margin-left:30px ;
    }
    .three p {
        margin-top: 20px ;
    }
        
}
