
.main{
    background-color: #026095;
    padding:10px;
}

.container {
    width: 60%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.one{
    display: flex;
    justify-content: space-between;
    margin:15px;
}


.title{
    font-family: hm;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: .6px;
}
.one button{
    outline:none;
    border:none;
    background-color: #ffffff;
    padding:5px 10px;
    font-family: hm;
    font-size: 18px;
    color: #026095;
    letter-spacing: .6px;
    border-radius: 16px;
    cursor: pointer;
}


@media only screen and (max-width: 1000px) { 
    
.main{
    background-color: #026095;
    padding:10px;
}

.container {
    width: 90%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.one{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin:15px;
}


.title{
    width: 100%!important;
    align-self: center!important;
    font-family: hm;
    text-align: center;
    font-size: 17px;
    color: #ffffff;
    letter-spacing: .6px;
}
.btn_div {
    align-self: center;
}
button{
    align-self: center;
    outline:none;
    border:none;
    width: 100%;
    background-color: #ffffff;
    padding:5px 10px;
    font-family: hm;
    font-size: 17px;
    margin-top: 20px;
    color: #026095;
    letter-spacing: .6px;
}


}