.main{
   
    background-image: url('../../Images/newImg2.jpg');
    
    height: 150vh;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover;
}
.one{
    position: relative;
    top: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
 
}
.main_info{
    align-self: flex-end;
    background-color: #026095;
    font-family: hl;
    font-size: 32px;
    line-height: 60px;
    color: #ffffff;
    padding:30px;
    margin:10px 0 0 0;
    width: 40%;
    letter-spacing: .6px;

}
.content {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    letter-spacing: .6px;
}
.div_one,.div_two,.div_three,.div_four{
    color: #ffffff;
    background-color: #fbae4c;
    text-align:center;
    padding: 10px;
    padding-top:50px ;
    padding-bottom: 50px;
    width: 50%;
}
.div_two{
    background-color: #e9a247;
}
.div_three{
    background-color: #ce8f3f;
}
.div_four{
    background-color: #b67f38;
}
 .logo i{
    font-size: 40px!important;
}
.div_five{
    width: 50%;
    height: 20px;
}
.div_six{
    width: 50%;
    text-align:center;
    padding: 10px;
    padding-top:30px ;
    padding-bottom: 30px;
    background-color: #ccdfea;
   
}
.div_six a {
    text-decoration: none;
    color: #ffffff;
    font-family: hm;
    font-size: 16px;
    color: #026095;
}
.div_six:hover{
    background-color: #e9a247;
}
h1{
    font-size: 22px;
    letter-spacing: .6px;
}
p{
    font-size: 15px;
    line-height: 16px;
    letter-spacing: .6px;
}


@media only screen and (max-width: 1000px) {
    .two{
        width: 50px;
        height:22%;
    }
    .main{
   
        background-image: url('../../Images/newImg2.jpg');
        
        height: 120vh;
        background-position: center;
        background-repeat: no-repeat;
        background-origin: content-box;
        background-size:cover;
        
    }
    .one{
        position: static!important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        
    }
    .main_info{
        align-self: flex-end;
        background-color: #026095;
        font-family: hl;
        font-size: 20px;
        line-height: 35px;
        color: #ffffff;
        padding:15px 10px;
        margin:10px 0 0 0;
        width: 90%;
        letter-spacing: .6px;
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        letter-spacing: .6px;
        
    }
    .div_one,.div_two,.div_three,.div_four{
        color: #ffffff;
        background-color: #fbae4c;
        text-align:center;
    
        padding-top:20px ;
        padding-bottom: 10px!important;
        width: 50%;
    }
    .div_two{
        background-color: #e9a247;
    }
    .div_three{
        background-color: #ce8f3f;
    }
    .div_four{
        background-color: #b67f38;
    }
     .logo i{
            font-size: 30px!important;
            margin-bottom: 10px;
        }
    .div_five{
        width: 50%;
        height: 20px;
    }
    .div_six{
        width: 50%;
        text-align:center;
        padding: 10px;
        padding-top:15px ;
        padding-bottom: 15px;
        background-color: #ccdfea;
       
    }
    .div_six a {
        text-decoration: none;
        color: #ffffff;
        font-family: hm;
        font-size: 14px;
        color: #026095;
    }
    .div_six:hover{
        background-color: #e9a247;
    }
    h1{
        font-size: 17px;
        margin-bottom: 7px;
        letter-spacing: .5px;
    }
    p{
        font-size: 14px;
        line-height: 18px;
        letter-spacing: .5px;
    }
    

}