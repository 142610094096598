.f_container{
    background: linear-gradient(135deg,#0079bf, #5067c5);  color: whitesmoke;
    margin-top:50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    align-items: center;
}
.f_container div{
    margin: 10px;
}
.social_links {
    display: flex;
    justify-content: space-between;
}
.social_links i {
    font-size: 27px;
    margin: 10px;
    margin-right: 40px;
}
.social_links a {
    color: #ffffff;
}
.social_links a:hover {
    color: black;
}
.app_header {
    font-size: 22px;
    font-family: hl;
    letter-spacing: .6px;
}
.app_img img {
   
    height: 35px;
    opacity: .8;
    cursor: pointer;
    margin:10px 15px ;
}
.app_img img:hover {
    opacity: 1;
}

@media only screen and (max-width: 1000px) { 
    .f_container{
        background: linear-gradient(135deg,#0079bf, #5067c5);  color: whitesmoke;
        margin-top:50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: center;
        align-items: center;
    }
    .f_container div{
        margin: 7px;
    }
    .social_links {
        display: flex;
        justify-content: space-between;
    }
    .social_links i {
        font-size: 28px;
        margin: 8px 25px;
        margin-right: 0px;
    }
    .social_links a {
        color: #ffffff;
    }
    .social_links a:hover {
        color: black;
    }
    .app_header {
        padding: 10px;
        font-size: 18px;
        font-family: hl;
        text-align: center;
    }
    .app_img img {
       
        height: 35px;
        opacity: .8;
        cursor: pointer;
        margin:10px 15px ;
    }
    .app_img img:hover {
        opacity: 1;
    }

}