
.main{
    background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);  

}
.main header{
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
}
.main header h3 {
       
        padding: 10px;
        font-family: hm;
        font-size: 28px;
        width: 40%;
        margin:  0 auto;
        color: #ffffff;
        font-family: hl;
        font-size: 32px;
        padding: 20px;
        text-align:center;   
        z-index: -10;
        margin-top: 70px;
        text-transform: uppercase;
        letter-spacing: .6px;
}
.container {
    width: 90%;
    margin:  0 auto;
    margin-top:50px ;
}

.one_main_container{
    background-color: #ffffff;

    margin: 20px auto;
    width: 100%;
    box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
    transform: scale(.9);
    transition:  all 600ms;
    padding: 10px 0;
}
.one_main_container:hover {
    transform: scale(.95);
     box-shadow: 0 15px 30px #b0adad, 0 10px 9px #b0adad;
     transition: all 600ms;
}
.one_main_container h3 {
    text-align: center;
    color: #0079bf;
    font-family: hl;
    font-size: 28px;
    letter-spacing: .6px;
    margin: 10px 0;
}
.one_container {
    display: flex;
}
.one_container_one, .one_container_two {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding:10px;
}

.one_container_one img, .one_container_two img {
    width:80%;
    margin: 0 auto;

    height: 300px;
    border-radius: 55px;
    box-shadow: 5px 5px 8px #b0adad;
}
.one_container p {
    font-family: hr;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: .6px;
    margin: 10px 0;
    color: #1d1d1f;
    
}
.one_container ul {
    width: 100%;
    margin: 0 auto;
}
.one_container ul li {
    list-style: none;
    margin: 0px 0;
    font-family: hr;
    font-size: 15px;
    letter-spacing: .6px;
    text-align: justify;
    line-height: 28px;
    color: #1d1d1f;
    display: flex;
    
    vertical-align: middle;
}
.container ul li i {
    margin-right:10px ;
    color: #026095;
    align-self: center;
}
.one_container ul li p {
    align-self: center;
    
}
.one_container_two ul li {
    color: #026095!important;
}
.one_container_two h4 {
    text-align: center;
    color: #026095;
    font-family: hl;
    font-size: 24px;
    letter-spacing: .6px;
    margin: 10px 0;  
}
.learn_more {
    display: none;
}

@media only screen and (max-width: 1000px) {

    .main{
        background-color: #f1f1f3;
    
    }
    .main header{
        width: 100%;
        height: 30vh;
        background: linear-gradient(135deg,#0079bf, #5067c5);
        border-top: 1px solid #ffffff;
    }
    .main header h3 {
           
            padding: 10px;
            font-family: hm;
            font-size: 24px;
            width: 90%;
            margin:  0 auto;
            color: #ffffff;
            font-family: hl;
            text-align:center;   
            z-index: -10;
            margin-top: 50px;
            text-transform: uppercase;
            letter-spacing: .6px;
            width: 90%;
        
    }
    .container {
        width: 100%;
        margin:  0 auto;
        margin-top:5px ;
        margin-top:25px;
        margin-bottom: 25px;
    }
    .container h3 {
     
        padding:15px 0 0 0 ;
    }
    .one_main_container ,.two_main_container{
        background-color: #ffffff;
        width: 100%;
        box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
        transform: scale(.95);
     
        transition:  all 600ms;
        margin: 30px auto!important;
    }
    .one_container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
    }
    .one_main_container:hover {
         transform: scale(.95);
         
         transition: all 600ms;
    }
    .one_container_one, .one_container_two {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding:10px 2px;
        transition: all 600ms;
        position: relative;
    }
    
    .one_container_one img, .one_container_two img {
        width:95%;
        margin: 0 auto;
        height: 200px;
        border-radius: 15px;
        box-shadow: 5px 5px 8px #b0adad;
    }
    .one_container p {
        font-family: hr;
        font-size: 14.5px;
        line-height: 28px;
        letter-spacing: .6px;
        margin: 10px 0;
        color: #1d1d1f;
        
    }
    .one_container ul {
        width: 90%;
        margin: 0 auto;
    }
    .one_container ul li {
        list-style: none;
        margin: 0 0;
        font-family: hr;
        font-size: 15px;
        letter-spacing: .6px;
        text-align: justify;
        line-height: 28px;
        color: #1d1d1f;
        display: flex;
        
        vertical-align: middle;
    }
    .container ul li i {
        margin-right:5px ;
        color: #0079bf;
        align-self: center;
    }
    .one_container ul li p {
        align-self: center;
    }
    .one_container_two h4 {
        text-align: center;
        color: #0079bf;
        font-family: hl;
        font-size: 24px;
        letter-spacing: .6px;
        margin: 5px 0;  
    }
    .two_container {
        display: flex;
        flex-direction: column;
        
    }
    .one_container_two ul li {
        color: #026095!important;
    }

    .one_container:last-child{
        margin-bottom: 25px;
    }

    .learn_more {
        display: block;
        text-align: center;
        width: 100%;
        margin: 0 auto;
        padding: 5px;
        color:#1d1d1f;
        cursor: default;
        background-color: #ffffff;
        color: #0079bf;
    }
    .learn_more i {
        font-size: 32px;
        margin-left:15px;
        position: relative;
        top:5px;
        transition: all 200ms;
    }
    .show_one_container_two {
        display: none;
        transition: all 600ms;
    }
    .rotate_learn_more i{
        transform: rotate(180deg);
        transition:  all 300ms;
    }
    

}