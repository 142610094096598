
.main{
    background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);  
}
.container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: #172b4d;
    background-color: #9fcee6;
    margin-top:50px ;
    border-radius: 25px;
    padding:10px;
}
.info{
    align-self: center;
    font-size: 25px;
    font-family: hm;
    width: 50%;
    text-align: center;
}
.location {
    width: 50%;
    border-radius: 25px;
    height: 250px;
}

@media only screen and (max-width: 1000px) { 
    .main{
        background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);  
    }
    .container {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #172b4d;
        background-color: #9fcee6;
        margin-top:50px ;
        border-radius: 25px;
        padding:20px 10px;
    }
    .info{
        margin-bottom: 10px;
        align-self: center;
        font-size: 20px;
        font-family: hm;
        width: 100%;
        text-align: center;
    }
    .location {
        width: 100%;
        border-radius: 25px;
        height: 300px;
    }
}