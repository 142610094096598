
.m_header {
    font-size: 26px;
    font-family: hl;
    color:#026095;
    letter-spacing: .6px;
    text-align: center;
    margin-top: 35px;
}
.m_one {
    margin-top: 35px;
    display: flex;
    justify-content: space-evenly;
}
.m_one p {
    font-size: 16px;
}
.m_img {
    width: 600px;
    height: 350px;
}

.m_img img {
    width: 100%;
    height: 100%;
    border-radius: 25px;
}
.m_content {
   
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.m_content a {
    width: 35%;
    
    align-self: center;
}
.m_content a button {
    width: 100%;
    
    align-self: center;
    outline:none;
    font-size: 16px;
    font-family: hm;
    border:none;
    background: #fbae4c;
    color:whitesmoke;
    padding:5px 10px;
}
.m_content_one header{
    font-family: hm;
    font-size: 18px;
    margin: 5px;
    letter-spacing: .7px;
    color: #fbae4c;
}
.m_content_one p{
    color: #026095;
    margin:5px;
    font-size: 16px;
    letter-spacing: .7px;
    font-family: hm;
}
.m_content_two{
    display: flex;
}


.one_div{
    padding: 20px;
    font-size: 15px;
    font-family: hr;
    border:1px solid #bbd5e2;
    width:200px;
    text-align: center;
}
.one_div p{
    color: #026095;
    font-family: hm;
}

@media only screen and (max-width: 1000px) { 

    
.m_header {
    font-size: 26px;
    font-family: hl;
    color:#026095;
    letter-spacing: .6px;
    text-align: center;
    margin-top: 5px;
}
.m_one {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.m_img {
    width: 300px;
    height: 200px;
    align-self: center;
}

.m_img img {
    width: 100%;
    height: 100%;
    border-radius: 1px;
}
.m_content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.m_content button {
    width: 50%;
    align-self: center;
    outline:none;
    font-size: 18px;
    font-family: hm;
    border:none;
    margin-top: 20px;
    margin: 20px 0;
    background: #fbae4c;
    color:whitesmoke;
    padding:8px 15px;
}
.m_content_one header{
    font-family: hl;
    font-size: 22px;
    margin-top: 20px;
    margin: 10px;
    letter-spacing: .6px;
    color: #fbae4c;
}
.m_content_one p{
    color: #026095;
    margin-top: 20px;
    margin:10px;
    font-size: 15px;
    letter-spacing: .7px;
    font-family: hm;
}
.m_content_two{
    display: flex;
    flex-wrap: wrap;
}


.one_div{
    padding: 15px;
    font-size: 16px;
    font-family: hr;
    border:1px solid #bbd5e2;
    width:100%;
    text-align: center;
}
.one_div p{
    color: #026095;
    font-family: hm;
    font-size: 18px;
}
.m_header {
    font-size: 21px;
}
   
}