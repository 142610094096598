.main{
    background-color: #ffffff;

}
.main header{
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;

}
.main header h3 {
    text-transform: uppercase;
    letter-spacing: .6px;
        padding: 10px;
        font-family: hm;
        font-size: 28px;
        width: 40%;
        margin:  0 auto;
        color: #ffffff;
        font-family: hl;
        font-size: 32px;
        padding: 20px;
        text-align:center;   
        z-index: -10;
        margin-top: 70px;
    
}
.container{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}
.container_one {
    width: 50%;
    margin: 0 auto;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
}

.container_two{
   
    width: 50%;
    margin: 0 auto;
}
.container_two h3 {
    text-align: center;
    letter-spacing: .6px;
    color: #0079bf;
    font-family: hm;
}
.content h3 {
    font-family: hm;
    font-size: 24px;
    letter-spacing: .6px;
    color: #0079bf;
    margin-top: 10px;
}
.content p {
    font-family: hr;
    font-size: 14px;
    letter-spacing: .6px;
    line-height:28px;
    margin-top: 10px;
    margin-bottom:10px ;
}
.box_one h3 {
    font-family: hm;
    font-size: 18px;
    color: #0079bf;
    letter-spacing: .6px;
}
.box_one {
    margin: 10px 0;
}
.box_one p {
    font-family: hr;
    font-size: 15px;
    letter-spacing: .6px;
    margin-top:10px ;
    line-height: 25px;
}
.address {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.container_two {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.form {
    align-self: center;
    width: 100%;
    display: flex;
    margin-top:25px ;
    flex-direction: column;
    justify-content: space-evenly;
}
.field {
    align-self: center;
    width: 70%;
    margin:  9px 0;
}
.field label {
    font-size: 14px;
    letter-spacing: .6px;
    line-height:28px;
    position: relative;
    top:28px;
    left: 2px;
   
    color: #949494;
    font-weight: bold;
    transition: all 300ms;
}
.form input {
    border:none;
    border-bottom: 3px solid #505050;
    width: 100%;
    padding: 5px;
}
.form input:focus{
    border:none;
    outline:none;
    border-bottom: 3px solid #505050;
}

.form textarea {
    border:none;
    outline:none;
}
.field button {
    width:40%;
    margin: 0 auto;
    align-self: center;
    background-color: black;
    color: white;
    letter-spacing: .6px;
    line-height:28px;
    padding: 5px 0px;
    font-size: 16px;
    outline: none;
    border:none;
    cursor: pointer;
}
.field button:active{
    position: relative;
    top:3px;
}

.label_style {
    position: relative;
    top:0px!important;
    transition: all 300ms;
}


.required {
    visibility: hidden;
    position: relative;
    display: block;
    color: tomato;
    font-size: 13px;
    font-family: hr;
    text-align: right;
    top:2px;
    left: -5px;
    transition: all 300ms;
}
.show_span {
    visibility: visible;     
    transition: all 300ms;
}

.style_border_green {
    border-bottom:3px solid rgb(119, 194, 6) !important;
}
.style_border_red {
    border-bottom:3px solid  tomato!important;
}
.aftermsg {
    letter-spacing: .6px;
    font-size: 14px;
    color: #0079bf;
    visibility: hidden;
}
.show_aftermsg {
    visibility: visible;
}

@media only screen and (max-width: 1000px) {
    .main{
        background-color: #ffffff;
    
    }
    .main header{
        width: 100%;
        height: 25vh;
        background: linear-gradient(135deg,#0079bf, #5067c5);
        border-top: 1px solid #ffffff;
        position: relative;
        overflow: hidden;
        z-index: 343;
    
    }
    .main header h3 {
        text-transform: uppercase;
        letter-spacing: .6px;
            padding: 10px;
            font-family: hm;
            font-size: 28px;
            width: 90%;
            margin:  0 auto;
            color: #ffffff;
            font-family: hl;
            font-size: 25px;
            padding: 20px;
            text-align:center;   
            z-index: -10;
            margin-top: 40px;
            letter-spacing: .6px;
    }
    .container{
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 0;
    }
    .container_one {
        width: 90%;
        margin: 0 auto;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
       
    }
    
    .container_two{
        position: relative;
        top: 0px;
        width: 100%;
        margin: 25px auto;
    }
    .container_two h3 {
        text-align: center;
        letter-spacing: .6px;
        color: #0079bf;
        font-family: hm;
    }
    .content h3 {
      
        font-family: hm;
        font-size: 24px;
        letter-spacing: .6px;
        color: #0079bf;
        margin-top: 4px;
    }
    .content p {
        font-family: hr;
        font-size: 14px;
        letter-spacing: .6px;
        line-height:28px;
        margin-top: 5px;
        margin-bottom:5px ;
    }
    .box_one h3 {
       
        font-family: hm;
        font-size: 18px;
        color: #0079bf;
        letter-spacing: .6px;
    }
    .box_one {
        margin: 5px 0;
    }
    .box_one p {
        font-family: hr;
        font-size: 14px;
        letter-spacing: .6px;
        margin-top:0px ;
       
    }
    .address {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    
    .container_two {
        margin-top:25px ;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .form {
        align-self: center;
        width: 100%;
        display: flex;
        margin-top:8px ;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .field {
        align-self: center;
        width: 85%;
        margin:  8px 0;
    }
    .field label {
        font-size: 14px;
        letter-spacing: .6px;
        line-height:28px;
        position: relative;
        top:28px;
        left: 2px;
       
        color: #949494;
        font-weight: bold;
        transition: all 300ms;
    }
    .form input {
        border:none;
        border-bottom: 1.5px solid #505050;
        width: 100%;
        padding: 3px;
    }
    .form input:focus{
        border:none;
        outline:none;
        border-bottom: 1.5px solid #505050;
    }
    
    .form textarea {
        border:none;
        outline:none;
    }
    .field button {
        width:40%;
        margin: 0 auto;
        align-self: center;
        background-color: black;
        color: white;
        letter-spacing: .6px;
        line-height:28px;
        padding: 5px 0px;
        font-size: 16px;
        cursor: pointer;
    }
    .field button:active{
        position: relative;
        top:3px;
    }
    
    .label_style {
        position: relative;
        top:0px!important;
        transition: all 300ms;
    }
    
    
    .required {
        visibility: hidden;
        position: relative;
        display: block;
        color: tomato;
        font-size: 13px;
        font-family: hr;
        text-align: right;
        top:2px;
        left: -5px;
        transition: all 300ms;
    }
    .show_span {
        visibility: visible;     
        transition: all 300ms;
    }
    
    .style_border_green {
        border-bottom:1.5px solid rgb(119, 194, 6) !important;
    }
    .style_border_red {
        border-bottom:1.5px solid  tomato!important;
    }   
}