

.main{
    background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);    
    margin-top:100px ;
}
.d_container {

    width: 80%;
    margin: 0 auto;
}
.d_container header{
    text-align: center;
    font-size: 26px;
    font-family: hm;
    color: #026095;
}
.images {
    display: flex;
    justify-content: space-between;
    margin-top:40px ;
}
.img {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #026095;
    text-align: center;
}
.img img {
    width: 180px;
    height: 180px;
    border-radius: 25px;
}
.img i {
    font-size: 155px;
}
.content p {
    margin: 10px 0;
    font-size: 16px;
    font-family: hm;
}
.content span {
    font-family: hr;
    font-size: 13px;
}
.m_container{
    display: none;
}
@media only screen and (max-width: 1000px) { 
    .main{
        margin-top: 0px;   
    }
    .d_container {
        display: none;
    }   
    .m_container {
        
        display: block;
        margin-top: 100px!important;
    }
    .m_container header{
        text-align: center;
        font-size: 20px;
        font-family: hl;
        color: #026095;
        margin: 15px;
    }
    }