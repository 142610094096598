.main {
    background-color: #ffffff;
}
.main header {
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.main header h3 {
    text-align: center;
    font-family: hm;
    font-size: 38px;
    line-height:.6px;
    margin: 20px 0;
    color: #ffffff;
}

.container{
    width: 90%;
    margin: 0 auto;

}
.one,.leadership_div {
    margin: 20px 0 ;
    background-color: #ebf8ff;
    padding: 15px;
}

.one h3, .leadership_div h3 {
    font-family: hm;
    letter-spacing: .6px;
    font-size: 24px;
    color: #026095;
}
.one p {
    font-size: 15px;
    letter-spacing: .6px;
    line-height: 28px;
    color: #72828b;
    font-family: hr; 
    margin: 10px 0 ;
    margin-left:25px ;  
    
}
.leadership {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.nine {
    margin: 10px;
    width: 30%;
    padding: 7px;
    background-color: #026095;
    box-shadow: 0 15px 30px #b0adad, 0 10px 9px #b0adad;
}
.nine h3 {
    font-size: 18px;
    letter-spacing: .6px;
    line-height: 28px;
    font-family: hr;
    font-weight: bold;
    color: #ffffff;
}
.nine p {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: .6px;
    font-family: hr;
    margin: 5px;
    margin-left: 10px;

}

.one h4 {
    color: #026095;
    font-size: 18px;
    font-family: hm;
    letter-spacing: .6px;
}
.one_sub {
    margin: 10px  0 0 25px;
}

.container:last-child {
    background-color: #ffffff;
}



@media only screen and (max-width: 1000px) {
    .main {
        background-color: #ffffff;
    }
    .main header {
        width: 100%;
        height: 25vh;
        background: linear-gradient(135deg,#0079bf, #5067c5);
        border-top: 1px solid #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .main header h3 {
        text-align: center;
        font-family: hm;
        font-size: 28px;
        line-height:.6px;
        margin: 20px 0;
        color: #ffffff;
    }
    
    .container{
        width: 95%;
        margin: 0 auto;
    }
    .one,.leadership_div {
        margin: 20px 0 ;
        background-color: #ebf8ff;
        padding: 15px;
    }
    
    .one h3, .leadership_div h3 {
        font-family: hm;
        letter-spacing: .6px;
        font-size: 20px;
        color: #026095;
    }
    .one p {
        font-size: 14px;
        letter-spacing: .6px;
        line-height: 26px;
        color: #72828b;
        font-family: hr; 
        margin: 10px 0 ;
        margin-left:25px ;  
        
    }
    .leadership {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .nine {
        margin: 10px;
        width: 90%;
        padding: 4px;
        align-self: center;
        background-color: #026095;
    }
    .nine h3 {
        font-size: 17px;
   
        letter-spacing: .6px;
        line-height: 26px;
        font-family: hr;
        font-weight: 300;
        color: #ffffff;
        margin: 0;
    }
    .nine p {
        color: #ffffff;
        font-size: 14px;
        letter-spacing: .6px;
        font-family: hr;
        margin: 0px;
        margin-left: 10px;
    }
    
    .one h4 {
        color: #026095;
        font-size: 18px;
        font-family: hm;
        letter-spacing: .6px;
    }
    .one_sub {
        margin: 10px  0 0 25px;
    }
    
    .container:last-child {
        background-color: #ffffff;
    }
    

}