.main {
    /* background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);   */
    background-color: #fff;
}
.main header {
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.main header h3 {
    text-align: center;
    font-family: hm;
    font-size: 38px;
    line-height:.6px;
    margin: 20px 0;
    color: #ffffff;
}
.main header p {
    line-height: 28px;
    margin: 10px 0;
    color: #ffffff;
    font-family: hr;
    letter-spacing: .9px;
}
.container{

    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom:45px ;
}
.one {
    width: 45%;
    height: 300px;
    margin: 20px;
    border-radius: 30px;
   
    box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
    transition: all 1s;
}
.one img {
    width: 100%;
    height: 100%;
    
}
.one:hover{
    transform: scale(1.1);
    transition: all 1s;
}

@media only screen and (max-width: 1000px) {
    .main {
        background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);  
    }
    .main header {
        width: 100%;
        height: 30vh;
        background: linear-gradient(135deg,#0079bf, #5067c5);
        border-top: 1px solid #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .main header h3 {
        text-align: center;
        font-family: hm;
        font-size: 26px;
        line-height:.6px;
        margin: 30px 0 10px 0 ;
        color: #ffffff;
    }
    .main header p {
        line-height: 28px;
        margin: 10px 0;
        color: #ffffff;
        font-family: hr;
        letter-spacing: .9px;
        font-size: 14px;
        text-align: center;
    }
    .container{
    
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom:45px ;
    }
    .one {
        width: 100%;
        height: 300px;
        margin: 10px 0; 
        transform: scale(.9);
        box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
  
    }
    .one img {
        width: 100%;
        height: 100%;
        border-radius: 30px;
    }
    .one:hover{
        transform: scale(1);
    }
}
