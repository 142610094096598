
.container {
    background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);  
}
.container header {
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
}

.title {
    text-transform: uppercase;
    letter-spacing: .6px;
    padding: 10px;
    font-family: hm;
    font-size: 28px;
    width: 40%;
    margin:  0 auto;
    color: #ffffff;
    font-family: hl;
    font-size: 32px;
    padding: 20px;
    text-align:center;   
    z-index: -10;
    margin-top: 70px;
}

.one_container {
    padding: 20px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.one_container .one {
    width: 100%;
}
.one p {
    font-family: hr;
    font-size: 15px;
    letter-spacing: .6px;
    line-height: 28px;
    margin-top:20px ;
     color: #1d1d1f;;
}
.one_container .two {
    width: 30%;
    margin-top:20px ;
    background-color: #ffffff;
}
.two {
    box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
}
.two h3 {
    background-color: #00587c;
    color: #ffffff;
    text-align:center;
    padding:  10px 20px;
  
}

.two ul {
    margin: 20px 10px;
    list-style: none;
}
.two ul li {
    list-style: none;
    margin:10px 45px ;
}
.two ul li a {
    font-family: hm;
    font-size: 14px;
 
    color: #00587c;
    line-height:28px;
    text-decoration: none;
}
.two ul li a:hover {
    text-decoration:  underline;
}
.two ul li a i {
    margin-right: 10px;
}



.two_container, .three_container_main,.six_container,.seven_container_main{
    background-color: #ffffff;
    width: 90%;
    margin: 0 auto;
    padding: 10px;
    transform: scale(1);
    box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
    margin:45px auto ;
    background-color:#EBF8FF ;
}
.two_container:hover, .three_container_main:hover,.six_container:hover,.seven_container_main:hover {
    transform: scale(1);
    transition:  all 600ms;
}

.two_container h3 {
    color: #026095;
    text-align: center;
    font-family: hm;
    letter-spacing: .6px;
    margin:10px 0;
    font-size:25px;
}
.two_wraper {
    display: flex;
    padding: 8px;
    
    flex-direction: row-reverse;
    justify-content: space-between;
}

.two_container_one {
    background-color: #FBAE4C;   
    padding: 15px;
}
.two_container_two {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.two_container_two p {
    font-family: hr;
    font-size: 15px;
    letter-spacing: .6px;
    line-height: 28px;
    margin:10px 0 0 10px;
    color:#677C88;
}
.two_container_one img {
    width: 400px;
    height: 250px;
    border-radius:0px;
}
.two_list h3 {
    font-size:21px;
    letter-spacing: .6px;
    color: #e65100 ;
}
.two_list ul {
    
    text-align: center;
}
.two_list ul li{
    display: inline-block;
    list-style: none;
    margin: 2px 25px;
    color: #1d1d1f;
    margin-top:10px ;
    padding:10px;
    font-family:hm;
}




.eight_container {
    background-color: #026095;
    margin-top:40px ;
}
.header1 {
    text-align: center;
    font-family: hm;
    letter-spacing: .4px;
    font-size: 24px;
    color: #ffffff;
    padding: 20px;
}

.eight_container_body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    
}
.eight_container_body p {
    font-size: 15px;
    font-family: hr;
    letter-spacing: .6px;
    line-height: 26px;
    padding: 5px;
    color: #1d1d1f;
}
.eight_container_body h3 {
    font-size: 18px;
    text-align: center;
    color: #0373eb;
    margin:  10px 0;
}
.eight_container_body div {
    width: 30%;
    background-color:#FBAE4C;
    color: #0373eb;
    margin: 14.4px;
    padding: 10px;
    box-shadow: 10px 10px 8px 0px #13375f;
}














@media only screen and (max-width: 1000px) {

    
.container {
    background-color:#ffffff;
}
.container header {
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
}

.title {
    text-transform: uppercase;
    letter-spacing: .6px;
    padding: 10px;
    font-family: hm;
    font-size: 28px;
    width: 100%;
    margin:  0 auto;
    color: #ffffff;
    font-family: hl;
    font-size: 24px;
    padding: 10px 10px;
    text-align:center;   
    z-index: -10;
    margin-top: 60px;
}

.one_container {
    padding: 8px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.one_container .one {
    width: 100%;
}
.one p {
    font-family: hr;
    font-size: 15px;
    letter-spacing: .6px;
    line-height: 28px;
    margin-top:20px ;
    color: #1d1d1f;;
}
.one_container .two {
    width: 30%;
    margin-top:20px ;
    background-color: #ffffff;
}
.two {
    box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
}
.two h3 {
    background-color: #00587c;
    color: #ffffff;
    text-align:center;
    padding:  10px 20px;
  
}

.two ul {
    margin: 20px 10px;
    list-style: none;
}
.two ul li {
    list-style: none;
    margin:10px 45px ;
}
.two ul li a {
    font-family: hm;
    font-size: 14px;
 
    color: #00587c;
    line-height:28px;
    text-decoration: none;
}
.two ul li a:hover {
    text-decoration:  underline;
}
.two ul li a i {
    margin-right: 10px;
}



.two_container, .three_container_main,.six_container,.seven_container_main{
    background-color: #ffffff;
    width: 95%;
    margin: 0 auto;
    padding: 10px;
    transform: scale(1);
    box-shadow: 0 19px 38px #b0adad, 0 15px 12px #b0adad;
    margin:45px auto ;
    background-color:#EBF8FF ;
}
.two_container:hover, .three_container_main:hover,.six_container:hover,.seven_container_main:hover {
    transform: scale(1);
    transition:  all 600ms;
}

.two_container h3 {
    color: #026095;
    text-align: center;
    font-family: hm;
    letter-spacing: .6px;
    margin:10px 0;
    font-size:22px;
}
.two_wraper {
    display: flex;
    padding: 5px;
    flex-direction: column-reverse;
    justify-content: space-between;
}
.two_wraper:nth-child(2) {
    flex-direction: column;   
}
.two_container_one {
    background-color: #FBAE4C;   
    padding: 5px;
}
.two_container_two {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px;
}
.two_container_two p {
    font-family: hr;
    font-size: 15px;
    letter-spacing: .6px;
    line-height: 28px;
    margin:10px 0 0 10px;
    color:#677C88;
}
.two_container_one img {
    width: 100%;
    height: 100%;
    border-radius:0px;
    margin-top:10px ;
}
.two_list h3 {
    font-size:20px;
    letter-spacing: .6px;
    color: #e65100 ;
}
.two_list ul {
    text-align: center;
}
.two_list ul li{
    display: inline-block;
    list-style: none;
    margin: 2px 25px;
    color: #1d1d1f;
    margin-top:10px ;
    padding:10px;
    font-family:hm;
}




.eight_container {
    background-color: #026095;
    margin-top:40px ;
}
.header1 {
    text-align: center;
    font-family: hm;
    letter-spacing: .4px;
    font-size: 24px;
    color: #ffffff;
    padding: 10px;
}

.eight_container_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    
}
.eight_container_body p {
    font-size: 15px;
    font-family: hr;
    letter-spacing: .6px;
    line-height: 26px;
    padding: 5px;
    color: #1d1d1f;
}
.eight_container_body h3 {
    font-size: 18px;
    text-align: center;
    color: #0373eb;
    margin:  10px 0;
}
.eight_container_body div {
    width: 90%;
    background-color:#FBAE4C;
    color: #0373eb;
    margin: 14.4px;
    padding: 5px;
    box-shadow: 10px 10px 8px 0px #13375f;
}





}