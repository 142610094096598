
.btn, .title{
    display: none;
}
.main{
    width: 90%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
}
.container {
    width: 100%;
    display: flex;
    flex-direction: row!important;
    justify-content: space-between;
}
.one{
    align-self: center;
    text-align: left!important;
    width: 50%;
}
.one div{
    font-family: hl;
    font-size:22px;
    letter-spacing: .7px;
    margin: 5px;
    color: #005288;
}
.one p {
    margin: 5px;
    font-size: 18px;
    font-family: hr;
    color: #005288;
    line-height: 26px;
  }
.one button {
  cursor: pointer;
    margin: 5px;
    outline:none;
    font-size: 18px;
    padding:5px 8px;
    border:none;
    color: #ffffff;
    font-family: hm;
    background-color: #005288;;
}
.one button a {
  color: #ffffff;
  font-family: hm;
  text-decoration: none;
}
.two{
   
    align-self: flex-end;
    display: flex;
    flex-wrap: wrap;
  
   width: 60%;
}
.one_div, .two_div, .three_div,.four_div{   
    color: #ffffff;
    font-size: 18px;
    font-family: hm;
    width: 300px;
    height: 100px;
    margin: 10px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
   
}

.img{
    width: 100px;
    height: 100px;
}



.imgsvg{
    width: 100px;
    height: 100px;
}

.one_div {
    background-color: transparent;
    width: 45%;
    height: 250px;
    perspective: 1000px;
    
}
  
  .one_fill_container {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .one_div:hover .one_fill_container {
    transform: rotateY(180deg);
  }

  .one_fill_front, .one_fill_back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    font-size: 18px;
    font-family: hm;
    border-radius: 55px;
}



  
.one_fill_front {
  background-color: #7FBA00  !important;
}
  
.one_fill_back {    
  background-color: #7FBA00  !important;
    transform: rotateY(180deg);
}

@media only screen and (max-width: 1000px) {
  .btn, .title{
    display: block;
}
.btn_div{
  align-self: center;
}
.btn_div a {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}
.btn {
  text-align: center;
  margin: 20px 0;
  outline:none;
  font-size: 18pxx;
  padding:8px 14px;
  border:none;
  align-self: center;
  width: 100%;
  color: #ffffff;
  font-family: hm;
  background-color: #005288;;
  
}

.title{
  font-family: hl;
  font-size:26px;
  letter-spacing: .7px;
  margin: 10px;
  color: #005288;
}
.one{
  display: none;
}
.main{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.container {
    width: 100%;
    display: flex;
    flex-direction: column!important;
    justify-content: space-between;
}


.two{
  width:100%;
}
.one_div,.two_div,.three_div,.four_div{
  width: 100%!important;
}
.img{
  width: 150px;
  height: 150px;
}
.one_div, .two_div, .three_div,.four_div{   
  color: #ffffff;
  font-size: 18px;
  font-family: hm;
  width: 90%!important;
  height: 200px!important;
  margin: 10px;
  margin:0 auto;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
 
 
}


.imgsvg{
  width: 100px;
  height: 100px;
}
.one_fill_front,.two_fill_front,.three_fill_front, .four_fill_front{
  font-size: 16px!important;
  border-radius: 0px!important;
  border-radius: 25px!important;
}
.one_fill_back,.two_fill_back,.three_fill_back, .four_fill_back{
  font-size: 16px!important;
  border-radius: 0px!important;
  border-radius: 25px!important;
}

}

























.two_div {
    background-color: transparent;
    width: 45%;
    height: 250px;
    perspective: 1000px;
    perspective: 1000px;
  }
  
  .two_fill_container {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .two_div:hover .two_fill_container {
    transform: rotateY(180deg);
  }

  .two_fill_front, .two_fill_back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    font-size: 16px;
    font-family: hm;
    border-radius: 55px;
}



  
.two_fill_front {
  background-color: #F25022;
   
}
  
.two_fill_back {    
  background-color: #F25022;
    transform: rotateY(180deg);
}


















.three_div {
    background-color: transparent;
    width: 45%;
    height: 250px;
    perspective: 1000px;
    perspective: 1000px;
  }
  
  .three_fill_container {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .three_div:hover .three_fill_container {
    transform: rotateY(180deg);
  }

  .three_fill_front, .three_fill_back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    font-size: 18px;
    font-family: hm;
    border-radius: 55px;
}

p{
    font-size: 18px;
    font-family: hm;   
}


  
.three_fill_front {
  background-color: #FFB900;
}
  
.three_fill_back {    
  background-color: #FFB900;
    transform: rotateY(180deg);
}




















.four_div {
    background-color: transparent;
    width: 45%;
    height: 250px;
    perspective: 1000px;
  }
  
  .four_fill_container {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .four_div:hover .four_fill_container {
    transform: rotateY(180deg);
  }

  .four_fill_front, .four_fill_back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    font-size: 18px;
    font-family: hm;
    border-radius: 55px;
}



  
.four_fill_front {
  background-color:#00A4EF;
}
  
.four_fill_back {    
  background-color:#00A4EF;
    transform: rotateY(180deg);
}














