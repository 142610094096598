.dropdown_one{
    background: linear-gradient(135deg,#0079bf, #5067c5);
    position: absolute;
    width: 100%;
    border:1px solid whitesmoke;
    left: -5%;
    display: none;
    top:85%;
    z-index: 34343434343;
    padding:0px;
}

.dropdown_one_container {
    display: flex;
    width: 100%;
    padding:2px 2px ;
    justify-content: space-between;
}

.section_two {
    width: 33.3%;
}
.section_three {
    width: 33.3%;
}
.section_one img {
    width:200px;
    height:200px;
    /* border-radius: 305px 305px 40px; */

}
.section_two h3, .section_three h3 {
    font-size: 19px;
    font-family: hr!important;

}
.section_two h3:hover, .section_three h3:hover{
    text-decoration: underline;
}
.uls {
    list-style: none;
}
.uls li {
    margin:6px 0 6px 10px ;
}
.uls li i {
    color: #ffffff;
    margin-right:10px ;
}
.uls li a {
    text-decoration: none;
    color: #ffffff;
    letter-spacing: .6px;
    line-height:28px;
    font-size: 12px!important;
    font-family: hr!important;
    letter-spacing: .6px;
    
}
.uls li a:hover {
    text-decoration: underline;
    
}


.section_two h3 a {
    color: #ffffff;
    font-family: hr;
    font-size: 12px;
    letter-spacing: .6px;
    text-decoration: none;
}
.section_three h3 a{
    color: #ffffff;
    font-family: hr;
    font-size: 12px;
    letter-spacing: .6px;
    text-decoration: none;
}

.dropdown_one p {
    font-size: 12px!important;
    font-family: hr;
    color: #ffffff;
    letter-spacing: .6px;
    line-height:28px;
    padding: 10px;


}

@keyframes dropdown_anim{
    from {
        opacity: 0.5;
    }
    to{
        opacity: 1;
    }
}

.show_dropdown_one {
    display: block;
    animation:  dropdown_anim 700ms;
}

.show_dropdown_two {
    display: block;
    animation:  dropdown_anim 700ms;
}


.show_dropdown_three {
    display: block;
    animation:  dropdown_anim 700ms;
}
.show_dropdown_four {
    display: block;
    animation:  dropdown_anim 700ms;
}
.btn_take_test {
    margin-top:25px ;
    display: inline-block;
    background-color: whitesmoke;
    padding:5px 7px;
    color: #0079bf;
    outline:none;
    border:none;
    font-size: 12px;
}

