

.main {
    background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);    
    margin-top:100px ;
}

.d_container{
    width:90%;
    margin:0 auto;
   
}   
.d_container header {
    text-align: center;
    color: #026095;
    font-family: hm;
    font-size: 26px;
    margin: 20px;
}

.patients {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #42548e 0%, #6b668c 100%);
    border-radius: 25px;
}

.patient{

    background-color: #ebecf0;
    border-radius: 20px;
    width: 35%;
    margin: 10px;
    padding:10px;
    max-height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.content p{
    background-color: #e0ecf4;
    letter-spacing: .6px;
    line-height: 31px;
    font-size: 14px;
    font-family: hr;
    padding:5px
}
.patient_info {
    display: flex;
}
.patient_info img{  
    width: 80px;
    height: 80px;
    border-radius: 55px;
    margin: 8px 0;
}
.name{
    margin-left: 10px;
    align-self: center;
}
.name p {
    font-size: 14px;
    letter-spacing: .6px;
    line-height: 27px;
}
.name span {
    font-size: 13px;
}

.stars i {
    color: gold;
}
.m_container{
    display: none;
}
@media only screen and (max-width: 1000px) { 
    .main {
        background: linear-gradient(to bottom, #fff 0%, #f7ecff 46%, #d2ecff 74%, #fff 100%);    
        margin-top:80px ;
    }
    
 .d_container{
     display: none;
 }
 .m_container {
     display: block;
     width: 90%;
     margin: 0 auto;
 }
 .m_container header{
    text-align: center;
    color: #026095;
    font-family: hm;
    font-size: 24px;
    margin: 20px;
 }
 .m_patients{
    background: linear-gradient(180deg, #42548e 0%, #6b668c 100%);
    
    border-radius: 20px;
    
}
 .m_patient {
    
     width: 100%;
     display: flex;
    
 }
 .m_content {
     width: 90%;
     margin: 0 auto;
     padding:10px;
     background-color: #ebecf0;
    border-radius: 10px;
     margin-top: 10px;    
}
 .m_content p{
     line-height: 28px;
     font-size: 14px;
     font-family: hr;
 }
 .m_stars {
     width: 90%;
     margin: 0 auto;
     padding:10px;
 }
 .m_stars i {
     color: gold;
 }
 .m_patient_info {
     width: 90%;
     margin:0 auto;
     padding:10px;
    display: flex;
    }
 .m_patient_info img {
     width: 100px;
     height: 100px;
     border-radius: 55px;
 }

}
