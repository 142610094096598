.main{

    background: linear-gradient(135deg,#0079bf, #5067c5);
    padding: 15px 10px;
    overflow: hidden;    
    border-top:1px solid #fff;

}
.container{
    margin-bottom: 100px;
    width: 90%;
    margin:0 auto;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
}

.one{
    width:60%;
    margin: 0 auto;
}

.main_info {
    font-size: 25px;
    line-height: 45px;
    font-family: hm;
    color: #fff;
    font-weight: 500;
    margin-top:20px;
}

.sub_info{
    margin-top:20px;
    font-size: 18px;
    font-family: hr;
    line-height: 38px;
    color: #fff;
}

.one button{
    margin-top:25px;
    background-color: #61bd4e;
    color: #fff;
    outline:none;
    cursor: pointer;
    border: none;
    padding:8px 12px;
    border-radius: 5px;
    font-size: 15px;
    font-family: hm;
    text-align:center;
    letter-spacing: .9px;
}
.one button a{
    text-decoration: none;
    color: #fff;
}
.ladies{
    background-color: #fff;
    color: #0079bf;
    padding:3px 8px;
    border-radius: 5px;
    font-family: hm;
}
.two{
   
    width: 45%;
}
.two img {
    width: 600px;
    height: 390px;
    border-radius: 10px;
}

@media only screen and (max-width: 1000px) {
    .main{
      
        background:#fff;
        border-top:none;
        padding:0;
        overflow: hidden;
    }
    .container{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }
    
    .one{
        
        position: relative;
        top:-50px;
        align-self: center;
        width: 90%;
        height: 300px;
        margin:0 auto;
        display: block;
        background: linear-gradient(135deg,#0079bf, #5067c5);
        color: whitesmoke;
    
    }

    .main_info {
        text-align: center;
        font-size: 20px;
        line-height: 30px;
        font-family: hl;
        color: #fff;
        font-weight: 500;
        margin-top:25px;
    }
    
    .sub_info{
        text-align: center;
        margin-top:8px;
        font-size: 16px;
        font-family: hr;
        line-height: 38px;
        color: #fff;
    }
    .one p{
        margin:20px 10px;
        font-size:14px;
        letter-spacing: .5px;
    }
    .one button{
        outline: none;
        border: none;
        color: whitesmoke;
        align-self: center;
        background-color: #61bd4e;
        width: 50%;
        padding:10px 8px;
        text-align: center;
        font-size: 14px;
        align-self: center;
        border-radius: 1px;
        position: relative;
        left: 25%;
        letter-spacing: .9px;
        font-family: hm;
        vertical-align: middle;
    }
    .ladies{
        vertical-align: middle;
        background-color:whitesmoke;
        color: #0079bf;
        padding:5px 8px;
        border-radius: 5px;
    }
    .two{
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
        height: 300px;
        
    }
    .two img {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }        
}
