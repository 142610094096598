
.main header{
    width: 100%;
    height: 30vh;
    background: linear-gradient(135deg,#0079bf, #5067c5);
    border-top: 1px solid #ffffff;
}
.main header h3 {
       
        padding: 10px;
        font-family: hm;
        font-size: 28px;
        width: 40%;
        margin:  0 auto;
        color: #ffffff;
        font-family: hl;
        font-size: 32px;
        padding: 20px;
        text-align:center;   
        z-index: -10;
        margin-top: 70px;
        text-transform: uppercase;
        letter-spacing: .6px;
}
.container{
    display: flex;
    justify-content:space-between;
    width:80%;
    margin:20px auto;

}
.l_container {
    display: flex;
    justify-content:center;
    flex-direction: column;
    width:40%;
    margin:20px auto;
}
.one h3 {
    font-size: 20px;
    letter-spacing: .6px;
}
.two {
    width: 50%;
}
.two p {
    font-size: 13px!important;
    letter-spacing: .6px;
    line-height: 28px;
    font-family: hr;
}
.form {
    width: 100%;
    margin: 25px 0;
}
.form label {
    font-size: 15px;
    font-family: hr;
}
.form input {
    border: none;
    outline: none;
    border-bottom:1px solid black;
    width: 80%;
    margin:8px 0 15px 0;
    font-size: 15px;
    font-family: hr;
    padding:5px;
}
.form input:hover {
    border: none;
    outline: none;
    border-bottom:1px solid black;
}
.form button {
    padding:10px;
    outline:none;
    border:none;
    background-color: black;
    color: white;
    font-size: 15px;
    letter-spacing: .6px;
    margin: 10px 0;
    width: 20%;
}
.form button:active{
    position: relative;
    top:3px;
}
.error {
    color: tomato;
    letter-spacing: .6px;
    margin: 10px 0;
    font-size: 15px;
    font-family: hm;
}
.three {
    display: flex;
    justify-content: space-between;
}
.logout {
    margin: 10px;
    outline: none;
    border: none;
    background-color: black;
    color: #ffffff;
    font-size: 15px;
    letter-spacing: .6px;
    padding:10px;
    display: flex;
    align-self: flex-end;
}
.logout:active{
    position: relative;
    top:3px;
}
.one h4 {
    text-align: center;
    margin-top: 35px;
    font-size: 58px;
    color: #0079bf;
    
}

.searchResults {
    position: absolute;
    top: 80%;
    left: 50%;
    background-color: #0079bf;
    color: #ffffff;
   
    width: 35%;
    overflow:scroll;
}
.email {
    margin: 20px;
    border: 1px solid whitesmoke;
    margin: 10px;
    cursor: pointer;
}
.email p{
    font-size: 16px;
    letter-spacing: .6px;
    padding:10px;
}

.extra_form {
    display: none;
}

.aftermsg {
    color: #0079bf;
    font-size: 30px!important;
    letter-spacing: .6px;
    font-family: hm!important;
    margin-top:-12px;
    visibility: hidden;
}
.aftermsg_show {
    visibility: visible;
}


@media only screen and (max-width: 1000px) {


    .main header{
        width: 100%;
        height: 25vh;
        background: linear-gradient(135deg,#0079bf, #5067c5);
        border-top: 1px solid #ffffff;
    }
    .main header h3 {
           
            padding: 10px;
            font-family: hm;
            font-size: 24px;
            width: 40%;
            margin:  0 auto;
            color: #ffffff;
            font-family: hl;
            font-size: 28px;
            padding: 10px;
            text-align:center;   
            z-index: -10;
            margin-top: 40px;
            text-transform: uppercase;
            letter-spacing: .6px;
    }
    .admin {
        width: 40%!important;
        color: #ffffff;
        font-family: hl;
        font-size: 28px!important;
        padding: 10px!important;
        text-align:center;   
        z-index: -10;
        margin-top: 40px;
    }
    .container{
        display: flex;
        justify-content:space-between;
        width:100%;
        margin:20px auto;
        flex-direction: column-reverse;
    
    }
    .l_container {
        display: flex;
        justify-content:center;
        flex-direction: column;
        width:95%;
        margin:0px auto;
    }
    .one h3 {
        font-size: 18px;
        letter-spacing: .6px;
        margin:5px;
    }
    .one {
        width:90%;
    }
    .two {
        width: 95%;
        margin: 0 auto;
    }
    .two p {
        font-size: 13px!important;
        letter-spacing: .6px;
        line-height: 28px;
        font-family: hr;
    }
    .form {
        
        width:100%;
        margin: 10px;
    }
    .form label {
        font-size: 15px;
        font-family: hr;
    }
    .form input {
        border: none;
        outline: none;
        border-bottom:1px solid black;
        width: 80%;
        margin:8px 0 10px 0;
        font-size: 15px;
        font-family: hr;
        padding:5px;
    }
    .form input:hover {
        border: none;
        outline: none;
        border-bottom:1px solid black;
    }
    .form button {
        padding:10px;
        outline:none;
        border:none;
        background-color: black;
        color: white;
        font-size: 15px;
        letter-spacing: .6px;
        margin: 10px 0;
        width: 20%;
    }
    .form button:active{
        position: relative;
        top:3px;
    }
    .error {
        color: tomato;
        letter-spacing: .6px;
        margin: 10px 0;
        font-size: 15px;
        font-family: hm;
    }
    .three {
        display: flex;
        justify-content: space-between;
    }
    .logout {
        margin: 10px;
        outline: none;
        border: none;
        background-color: black;
        color: #ffffff;
        font-size: 15px;
        letter-spacing: .6px;
        padding:10px;
        display: flex;
        align-self: flex-end;
    }
    .logout:active{
        position: relative;
        top:3px;
    }
    .one h4 {
        text-align: center;
        margin-top: 12px;
        font-size: 38px;
        color: #0079bf;
        
    }
    
    .searchResults {
        position: absolute;
        top: 80%;
        left: 50%;
        background-color: #0079bf;
        color: #ffffff;
       
        width: 35%;
        overflow:scroll;
    }
    .email {
        margin: 20px;
        border: 1px solid whitesmoke;
        margin: 10px;
        cursor: pointer;
    }
    .email p{
        font-size: 16px;
        letter-spacing: .6px;
        padding:10px;
    }
    
    .extra_form {
        display: none;
    }
    
    .aftermsg {
        color: #0079bf;
        font-size: 30px!important;
        letter-spacing: .6px;
        font-family: hm!important;
        margin-top:-12px;
        visibility: hidden;
    }
    .aftermsg_show {
        visibility: visible;
    }
}